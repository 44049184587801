<template>
  <section id="verificator">
    <h1>
      {{ $t('verification') }}
    </h1>
    <h5 class="text-primary">
      {{ $t('conectores') }}
    </h5>
    <b-row class="match-height mt-2">
      <b-col>
        <b-overlay
          variant="transparent"
          :show="show"
        >
          <b-card lazy>
            <b-card-header>
              <b-card-title>{{ $t('conectores') }}</b-card-title>

              <div class="d-flex align-items-center">
                <b-button
                  variant="success"
                  @click="addVerificator()"
                >
                  {{ $t('Add') }}
                </b-button>
              </div>
            </b-card-header>
            <b-card-body>
              <b-row>
                <b-col class="ml-2">
                  <b-form-group class="mb-2 mr-1">
                    <b-row>
                      <b-col v-if="allUserVerificator.length != 0">
                        <b-table
                          :striped="true"
                          sticky-header="500px"
                          :no-border-collapse="true"
                          responsive="sm"
                          :items="allUserVerificator"
                          :fields="columnsVerificator"
                        >
                          <template #cell(name)="data">
                            <b-row style="align-items: center;">
                              <b-col
                                cols="3"
                                class="embed-responsive embed-responsive-16by9"
                              >
                                <b-img
                                  class="embed-responsive-item cursor-pointer"
                                  :src="data.item.node.image"
                                  style="object-fit: contain;"
                                  loading="lazy"
                                  alt="image"
                                  @error="errorImg"
                                />
                              </b-col>

                              <b-col id="acortarPadre">
                                <h5 id="acortar">
                                  {{ data.item.node.name }}
                                </h5>
                              </b-col>
                            </b-row>
                          </template>
                          <template #cell(isActive)="data">
                            <div class="text-nowrap">
                              <b-badge
                                v-if="data.item.node.isActive"
                                variant="success"
                                class="badge"
                              >
                                {{ $t('activado') }}
                              </b-badge>
                              <b-badge
                                v-else
                                variant="danger"
                                class="badge"
                              >
                                {{ $t('desactivado') }}
                              </b-badge>
                            </div>
                          </template>
                          <template #cell(key)="data">
                            <div class="text-nowrap">
                              {{ data.item.node.key }}
                            </div>
                          </template>
                          <template #cell(createdAt)="data">
                            <div class="text-nowrap">
                              {{ formatDate(data.item.node.createdAt) }}
                            </div>
                          </template>
                          <template #cell(actions)="data">
                            <div class="text-nowrap d-flex">
                              <b-col>
                                <feather-icon
                                  icon="Edit2Icon"
                                  class="cursor-pointer text-success mr-2"
                                  size="18"
                                  @click="editVerificator(data.item.node.id)"
                                />
                                <feather-icon
                                  icon="Trash2Icon"
                                  class="cursor-pointer text-danger"
                                  size="18"
                                  @click.stop.prevent="deleteVerificator(data.item.node.id)"
                                />
                              </b-col>
                            </div>
                          </template>
                        </b-table>
                      </b-col>
                    </b-row>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
    <b-modal
      ref="form-view"
      size="xl"
      hide-footer
      :title="$t('Menu')"
    >
      <div>
        <b-tabs
          v-if="languageService && !createBool"
          id="languages"
          v-model="tabIndex"
          pills
          lazy
          vertical
        >
          <b-tab
            v-for="i in locales"
            :key="i.locale"
            class="align-items-start"
            style="align-items: flex-start;"
          >
            <template #title>
              <div class="">
                <b-img
                  :src="i.img"
                  height="14px"
                  width="22px"
                  :alt="i.locale"
                />
                <strong class="ml-50">{{ i.name }}</strong>
              </div>
            </template>

            <verificatorView
              :id="idVerificator"
              :key="i.locale"
              :create-bool="createBool"
              :action="action"
              :language="i.locale"
              :base="base"
              @refresh="getVerificator()"
            />
          </b-tab>
        </b-tabs>
        <verificatorView
          v-else
          :id="idVerificator"
          :action="action"
          :language="base"
          :base="base"
          :order-menu="orderMenu"
          :create-bool="createBool"
          @refresh="getVerificator()"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideModal"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
  </section>
</template>

<script>
import { messageError, showToast } from '@/store/functions'
import { required } from '@validations'
import '@core/assets/fonts/icons/css/all.css'
import '@core/assets/fonts/icons/scss/fontawesome.scss'
import {
  BRow,
  BCol,
  BCardHeader,
  BCardBody,
  BTab, BTabs,
  BTable,
  BButton,
  BBadge,
  BFormGroup,
  BCard,
  BCardTitle,
  BImg,
  BOverlay,
} from 'bootstrap-vue'
import axios from '@axios'
import Ripple from 'vue-ripple-directive'
import { getUserData } from '@/auth/utils'
import localesLanguages from '@core/utils/languageModels'
import verificatorView from './verificatorView.vue'

const fileError = require('@/assets/images/backend/file-error.png')

export default {
  components: {
    BOverlay,
    BCardHeader,
    BCardBody,
    BFormGroup,
    BTab,
    BTabs,
    BRow,
    verificatorView,
    BCol,
    BTable,
    BBadge,
    BButton,
    BCard,
    BImg,
    BCardTitle,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      createBool: false,
      menuType: 'CAT',
      menuOptions: [
        { value: 'CAT', text: this.$t('zones.category') },
        { value: 'INT', text: this.$t('dataGeneric.internal') },
        { value: 'URL', text: 'Url' },
      ],
      activeMenu: false,
      action: null,
      title: null,
      order: null,
      userData: getUserData(),
      idVerificator: null,
      checkbox: false,
      tabIndex: 0,
      categoryName: null,
      categoryId: null,
      show: false,
      headers: {},
      allUserVerificator: [],
      prefix: null,
      languages: [],
      languageService: true,
      locales: [],
      icon: null,
      orderMenu: 0,
      contentId: null,
      contentFileUrl: null,
      nActive: 0,
      manual: false,
      originalStatus: false,
      categorySelectorTable: [
        { key: 'image', label: this.$t('contents.category') },
        { key: 'name', label: this.$t('firstName') },
        { key: 'type', label: this.$t('type') },
      ],
      columnsVerificator: [
        { key: 'name', label: this.$t('dataGeneric.name') },
        { key: 'isActive', label: this.$t('code.status') },
        { key: 'key', label: 'key' },
        { key: 'createdAt', label: this.$t('subscriptions.createdAt') },
        { key: 'actions', label: this.$t('resources.tableHeader.actions') },
      ],
      activeCat: false,
      messageDeactive: '',
      activeIcon: true,
    }
  },

  mounted() {
    if (!this.userData.isSuperuser) {
      this.$router.push({ name: 'dashboard' })
    }
    let defaultLang = 'es'
    try {
      defaultLang = this.userData.profile.client.defaultLanguage
      // eslint-disable-next-line no-empty
    } catch (error) { }

    this.headers = {
      'Accept-Language': defaultLang,
      'Content-Language': defaultLang,
    }

    this.getClient()
    this.getVerificator()
  },
  methods: {
    getLocaleFormat(languagesString) {
      const idiomas = languagesString.split('|')

      const idiomasFormateados = idiomas.map(idioma => {
        const partes = idioma.split('-') // Dividir el idioma en partes usando '-'
        const idiomaFormateado = partes
          .map(parte => parte.charAt(0).toUpperCase() + parte.slice(1)) // Capitalizar cada parte
          .join('') // Unir las partes sin espacio
        return idiomaFormateado
      })
      return idiomasFormateados
    },
    getClient() {
      axios
        .post('', {
          query: `
                query{
                client(id:"${this.userData.profile.client.id}"){
                    externalPayMethod
                    currency
                    defaultLanguage
                    availableLanguages
                }
            }
            `,
        })
        .then(res => {
          const { availableLanguages } = res.data.data.client

          const arrayLanguages = this.getLocaleFormat(availableLanguages)

          this.languageService = arrayLanguages.length > 1
          this.base = `${this.getLocaleFormat(res.data.data.client.defaultLanguage)}`
          const languages = localesLanguages.filter(locale => arrayLanguages.includes(locale.locale))

          const baseObject = languages.find(obj => obj.locale === this.base)
          const filteredArray = languages.filter(obj => obj.locale !== this.base)
          this.locales = [baseObject, ...filteredArray]
        })
        .catch(() => {
        })
    },
    formatDate(value) {
      const subscriptionDate = new Date(value)
      const newDate = subscriptionDate.toLocaleDateString('en-GB')
      return newDate
    },
    addVerificator() {
      this.idVerificator = null
      this.createBool = true
      this.orderMenu = this.allUserVerificator.length !== 0
        ? this.allUserVerificator[this.allUserVerificator.length - 1].node.order + 1
        : 0

      this.$refs['form-view'].show()
    },
    errorImg(e) {
      e.target.src = fileError
    },
    editVerificator(id) {
      this.createBool = false
      this.idVerificator = id
      this.$refs['form-view'].show()
    },

    hideModal() {
      this.$refs['form-view'].hide()
    },
    getVerificator() {
      this.hideModal()
      this.show = true
      const { headers } = this
      axios
        .post('', {
          query: `
          {
            allUserVerificator(client:"${this.userData.profile.client.id}") {
                edges {
                    node {
                        id
                        name
                        fieldsFrontNames
                        createdAt
                        updatedAt
                        lambdaUrl
                        key
                        isActive
                        lambdaExtraConfig
                        secret
                        key
                        fields
                        responseUserIdField
                        image
                        verifieldUsers {
                        edges {
                            node {
                            id
                            }
                        }
                        }
                     }
                    }
                }
            }
            `,
        }, { headers })
        .then(res => {
          messageError(res, this)
          this.allUserVerificator = res.data.data.allUserVerificator.edges

          this.show = false
        }).catch(() => {
          this.show = false
        })
    },
    deleteVerificator(id) {
      this.show = true
      this.$swal({
        title: this.$t('code.title'),
        text: this.$t('code.text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios
            .post('', {
              query: `
            mutation{
              deleteUserVerificator(id:"${id}"){
                found
                deletedId
              }
            }
            `,
            })
            .then(res => {
              messageError(res, this)

              this.getVerificator()
              showToast(this.$t('success'), 1, this)
            })
            .catch(err => {
              console.error(err)
              this.show = false

              showToast(this.$t('error'), 2, this)
            })
        } else {
          this.show = false
        }
      })
    },

  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";

#verificator .card-body {
  padding: 1rem;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  min-height: 1px;
}

#verificator .card-body h4 {
  font-size: 1.286rem !important;
}

#verificator .col-form-label {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

#verificator .fly-image-input {
  max-width: 100%;
}

#verificator .base-color-input {
  display: block;
  border-radius: 15px;
  border: solid 1px;
  border-color: white;
  cursor: pointer;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#verificator .base-card-input {
  display: block;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

#verificator .div {
  display: inline-block;
}

#verificator #inputColor {
  visibility: hidden;
}

#verificator #backgroundColor {
  visibility: hidden;
}

#verificator .inputColor {
  width: auto;
  color: white;

  background-size: cover;
  background-position: center center;
}

#verificator .placeholder {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}

#verificator .placeholder:hover {
  background: #e0e0e0;
}

#verificator .file-input {
  display: none;
}

#verificator .cBox {
  align-items: center;
}

#verificator .custom-control-label {
  font-size: 15px;
}

#verificator .scroll {
  overflow: hidden;
  flex-wrap: nowrap !important;

}

#verificator .list {
  overflow: hidden;
  overflow-y: scroll;
  height: 380px;
}

#verificator ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  display: none;
}

#verificator .contain {
  object-fit: contain;
  cursor: pointer;
}

#verificator .active-info {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  font-weight: bold;
  color: aquamarine;
  font-size: smaller;
}

#verificator .dropContainer {
  border: 3px dashed;
}

#verificator .base-image-inputIm {
  display: block;

  width: 5vh;
  // height: 2vh;
  // margin: auto;
  object-fit: contain;
  -webkit-mask-size: contain;
}
</style>
