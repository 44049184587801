<template>
  <section id="menu-view">
    <b-row>
      <b-col>
        <b-overlay
          variant="transparent"
          :show="show"
        >
          <b-card
            no-body
            lazy
          >
            <b-card-header class="p-0 mb-2 mt-2">
              <b-card-title>
                {{ $t('menuConf') }}
              </b-card-title>
              <b-button
                class="save-button"
                variant="success"
                @click="validationFormInfo()"
              >
                {{ $t("dataGeneric.save") }}
              </b-button>
            </b-card-header>
            <b-form @submit.prevent="validationFormInfo">
              <validation-observer
                ref="menuRules"
                tag="form"
              >
                <b-row>
                  <b-col>
                    <validation-provider
                      v-slot="validationContext"
                      name="fieldsFrontNames"
                      rules="required"
                    >
                      <b-form-group :label="$t('fieldsFrontNames') + ', ' + $t('separated')">
                        <b-form-input
                          id="fieldsFrontNames"
                          v-model="fieldsFrontNames"
                          :placeholder="$t('fieldsFrontNames')"
                          :state="validationContext.errors.length > 0 ? false : null
                          "
                          autocomplete="new-password"
                        />
                        <small class="text-danger">{{
                          validationMessage(validationContext)
                        }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    v-if="language == base"
                    md="6"
                  >
                    <validation-provider
                      v-slot="validationContext"
                      name="name"
                      rules="required"
                    >
                      <b-form-group :label="$t('name')">
                        <b-form-input
                          id="name"
                          v-model="name"
                          :placeholder="$t('name')"
                          :state="validationContext.errors.length > 0 ? false : null
                          "
                          autocomplete="new-password"
                        />
                        <small class="text-danger">{{
                          validationMessage(validationContext)
                        }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row v-if="language == base">
                  <b-col>
                    <validation-provider
                      v-slot="validationContext"
                      name="lambdaUrl"
                      rules="required"
                    >
                      <b-form-group :label="'Lambda Url'">
                        <b-form-input
                          id="lambdaUrl"
                          v-model="lambdaUrl"
                          :placeholder="'Lambda Url'"
                          :state="validationContext.errors.length > 0 ? false : null
                          "
                          autocomplete="new-password"
                        />
                        <small class="text-danger">{{
                          validationMessage(validationContext)
                        }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row v-if="language == base">
                  <b-col>
                    <b-form-group :label="$t('lambdaExtraConfig')">
                      <b-form-textarea
                        id="lambdaExtraConfig"
                        v-model="lambdaExtraConfig"
                        autocomplete="new-password"
                        placeholder="Enter JSON here"
                        :state="!isEmpty(jsonError) ? false : null"
                        @change="validateJSON(lambdaExtraConfig)"
                      />
                      <small class="text-danger">{{
                        jsonError
                      }}</small>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-if="language == base">
                  <b-col>
                    <validation-provider
                      v-slot="validationContext"
                      name="key"
                      rules="required"
                    >
                      <b-form-group :label="$t('key')">
                        <b-form-input
                          id="key"
                          v-model="key"
                          :placeholder="$t('key')"
                          :state="validationContext.errors.length > 0 ? false : null
                          "
                          autocomplete="new-password"
                        />
                        <small class="text-danger">{{
                          validationMessage(validationContext)
                        }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col>
                    <validation-provider
                      v-slot="validationContext"
                      name="secret"
                      rules="required"
                    >
                      <b-form-group :label="$t('secret')">
                        <b-form-input
                          id="secret"
                          v-model="secret"
                          :placeholder="$t('secret')"
                          :state="validationContext.errors.length > 0 ? false : null
                          "
                          autocomplete="new-password"
                        />
                        <small class="text-danger">{{
                          validationMessage(validationContext)
                        }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row v-if="language == base">
                  <b-col>
                    <validation-provider
                      v-slot="validationContext"
                      name="fields"
                      rules="required"
                    >
                      <b-form-group :label="$t('fields') + ', ' + $t('separated')">
                        <b-form-input
                          id="fields"
                          v-model="fields"
                          :placeholder="$t('fields')"
                          :state="validationContext.errors.length > 0 ? false : null
                          "
                          autocomplete="new-password"
                        />
                        <small class="text-danger">{{
                          validationMessage(validationContext)
                        }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col>
                    <validation-provider
                      v-slot="validationContext"
                      name="responseUserIdField"
                      rules="required"
                    >
                      <b-form-group :label="$t('responseUserIdField')">
                        <b-form-input
                          id="responseUserIdField"
                          v-model="responseUserIdField"
                          :placeholder="$t('responseUserIdField')"
                          :state="validationContext.errors.length > 0 ? false : null
                          "
                          autocomplete="new-password"
                        />
                        <small class="text-danger">{{
                          validationMessage(validationContext)
                        }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row v-if="language == base">
                  <b-col>
                    <b-form-group>
                      <label>{{ $t('dataGeneric.activeQ') }}

                      </label>
                      <b-form-checkbox
                        id="isActive"
                        v-model="isActive"
                        :switch="true"
                      >
                        {{ $t('dataGeneric.active') }}
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-if="language == base">
                  <b-col>
                    <b-form-group :label="$t('Image')">
                      <b-row>
                        <image-element
                          :image-origin="image"
                          :image-type="'image'"
                          :label="''"
                          md-length="6"
                          :can-delete="false"
                          @deleteImage="deleteImage"
                          @saveImage="loadImage"
                        />
                      </b-row>
                    </b-form-group>
                  </b-col>
                </b-row>
              </validation-observer>
            </b-form>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </section>
</template>
<script>
import {
  messageError, showToast, isEmpty,
} from '@/store/functions'
import { required } from '@validations'
import '@core/assets/fonts/icons/css/all.css'
import '@core/assets/fonts/icons/scss/fontawesome.scss'
import {
  BForm,
  BRow,
  BCol,
  BFormCheckbox,
  BCardHeader,
  BCardTitle,
  BFormInput,
  BFormTextarea,
  BButton,
  BFormGroup, VBTooltip,
  BCard,
  BOverlay,
} from 'bootstrap-vue'
import axios from '@axios'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { getUserData } from '@/auth/utils'
import ImageElement from '@/views/components/ImageElement.vue'

const fileError = require('@/assets/images/backend/file-error.png')
const noCover = require('@/assets/images/backend/nocover.jpeg')

export default {
  components: {
    BOverlay,
    BCardHeader,
    BCardTitle,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BFormTextarea,
    BCol,
    BFormCheckbox,
    BFormInput,
    ImageElement,
    BButton,
    BCard,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    language: {
      type: String,
      default: null,
    },
    base: {
      type: String,
      default: null,
    },
    action: {
      type: String,
      default: null,
    },
    createBool: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isEmpty,
      image: null,
      key: '',
      keyMenuOptions: {
        home: this.$t('contents.start'),
        search: this.$t('contents.search'),
        epg: 'Epg',
      },
      lambdaUrl: '',
      name: '',
      secret: '',
      responseUserIdField: 'id',
      fields: null,
      required,
      menuType: 'CAT',
      lambdaExtraConfig: null,
      menuOptions: [
        { value: 'CAT', text: this.$t('contents.category') },
        { value: 'INT', text: this.$t('dataGeneric.internal') },
        { value: 'URL', text: 'Url' },
      ],
      isActive: false,
      actionMenu: null,
      fieldsFrontNames: null,
      userData: getUserData(),
      idMenuCat: null,
      checkbox: false,
      categoryName: null,
      categoryId: null,
      options: [
        { value: 'CON', text: 'Media' },
        { value: 'FIL', text: 'File' },
      ],
      show: false,
      headers: {},
      allMenu: [],
      prefix: null,
      icon: null,
      contentId: null,
      contentFileUrl: null,
      manual: false,
      originalStatus: false,
      categorySelectorTable: [
        { key: 'image', label: this.$t('contents.category') },
        { key: 'name', label: this.$t('firstName') },
        { key: 'type', label: this.$t('type') },
      ],
      activeCat: false,
      jsonError: '',
      messageDeactive: '',
    }
  },

  mounted() {
    if (!this.userData.isSuperuser) {
      this.$router.push({ name: 'dashboard' })
    }
    let defaultLang = 'es'
    try {
      defaultLang = this.userData.profile.client.defaultLanguage
      // eslint-disable-next-line no-empty
    } catch (error) { }

    this.headers = {
      'Accept-Language': defaultLang,
      'Content-Language': defaultLang,
    }
    if (this.id) this.getVerificatorById()
  },
  methods: {
    validateJSON(field) {
      if (isEmpty(field)) {
        this.jsonError = ''
        this.lambdaExtraConfig = null
      } else {
        try {
          JSON.parse(field)
          this.jsonError = ''
        } catch (e) {
          console.log(e)
          this.jsonError = this.$t('jsonError')
        }
      }
    },
    loadImage(item) {
      const { data, type } = item

      switch (type) {
        case 'image':
          this.image = data
          break

        default:
          break
      }
    },
    deleteImage(type) {
      this.$swal({
        title: this.$t('removData'),
        text: this.$t('sure'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.isConfirmed) {
            const config = {
              timeout: 1000 * 60 * 30,
            }
            const data = new FormData()
            const query = `
                    mutation {
                      updateVerificator(id: "${this.id}", input:{}) 
                      {
                        menu {
                          id
                          order
                        }
                      }
                    }
                  `
            data.append('query', query)
            switch (type) {
              case 'image':
                this.image = null
                data.append('deleteFile', 'icon_image')
                break
              default:
                break
            }

            axios
              .post('', data, config)
              .then(createResponse => {
                messageError(createResponse, this)

                showToast(this.$t('success'), 1, this)
              })
              .catch(res => {
                showToast(this.$t('error'), 0, this)
                this.showFiles = false
                console.log(res)
              })
          }
        })
        .catch(err => { console.log(err) })
    },
    errorImg(e) {
      e.target.src = fileError
    },

    chooseInput(dato) {
      document.getElementById(dato).click()
    },

    SelectContentId(data) {
      this.contentId = data
      this.hideModal()
    },
    SelectContentUrl(data) {
      this.videoPr = data
      this.hideModal()
    },

    hideModal() {
      this.$refs['my-modal'].hide()
      this.$refs['cat-modal'].hide()
      this.$refs.iconModal.hide()
    },
    getVerificatorById() {
      this.show = true
      const { headers } = this
      axios
        .post('', {
          query: `
             query{
              verificator(id:"${this.id}") {                
                id
                name
                fieldsFrontNames(lang:"${this.language}")
                createdAt
                updatedAt
                lambdaUrl
                isActive
                lambdaExtraConfig
                secret
                key
                fields
                responseUserIdField
                image
                verifieldUsers {
                  edges {
                      node {
                        id
                    }
                  }
              }
            }
          }
            `,
        }, { headers })
        .then(res => {
          messageError(res, this)
          const item = res.data.data.verificator

          this.fieldsFrontNames = item.fieldsFrontNames
          this.name = item.name
          this.lambdaExtraConfig = item.lambdaExtraConfig

          this.key = item.key
          this.secret = item.secret
          this.fields = item.fields
          this.responseUserIdField = item.responseUserIdField
          this.lambdaUrl = item.lambdaUrl
          this.menuType = item.type
          this.image = item.image !== '' ? item.image : ''

          this.isActive = item.isActive

          this.show = false
        })
    },
    addVerificator() {
      this.show = true
      const { headers } = this
      const config = {
        timeout: 1000 * 60 * 30,
      }
      const data = new FormData()
      const query = `
          mutation(
            $key: String,
            $secret: String,
            $fields: String!,
            $fieldsFrontNames: String!,
            $lambdaUrl: String!,
            $name: String!,
            $responseUserIdField: String,
            $lambdaExtraConfig: JSONString,
            $isActive: Boolean!,
          ){
            createUserVerificator(input:{client:"${this.userData.profile.client.id}",
              key:$key,
              secret:$secret,
              fields:$fields,
              fieldsFrontNames:$fieldsFrontNames,
              lambdaUrl:$lambdaUrl,
              name:$name,
              responseUserIdField:$responseUserIdField,
              lambdaExtraConfig:$lambdaExtraConfig,
              isActive:$isActive,
              }){
                vUserVerificator{
                  id
                }
              }
            }        
            `
      const variables = {
        key: this.key,
        fields: this.fields,
        secret: this.secret,
        fieldsFrontNames: this.fieldsFrontNames,
        lambdaUrl: this.lambdaUrl,
        name: this.name,
        responseUserIdField: this.responseUserIdField,
        lambdaExtraConfig: this.lambdaExtraConfig,
        isActive: this.isActive,
      }
      data.append('query', query)
      data.append('image', this.image)
      data.append('variables', JSON.stringify(variables))

      axios
        .post('', data, { headers }, config)
        .then(res => {
          messageError(res, this)
          showToast(this.$t('success'), 1, this)
          this.$emit('refresh')
        })
        .catch(() => {
          this.show = false
          showToast(this.$t('error'), 2, this)
        })
    },
    updateVerificator(id) {
      this.show = true
      const { headers } = this

      const config = {
        timeout: 1000 * 60 * 30,
      }

      const data = new FormData()

      let objectVariables = []
      objectVariables = [
        { title: `fieldsFrontNames${this.language}`, value: this.fieldsFrontNames, type: 'String' },
      ]
      if (this.language === this.base) {
        objectVariables.push(
          { title: 'key', value: this.key, type: 'String' },
          { title: 'secret', value: this.secret, type: 'String' },
          { title: 'fields', value: this.fields, type: 'String' },
          { title: 'responseUserIdField', value: this.responseUserIdField, type: 'String' },
          { title: 'lambdaUrl', value: this.lambdaUrl, type: 'String' },
          { title: 'name', value: this.name, type: 'String' },
          { title: 'lambdaExtraConfig', value: this.lambdaExtraConfig, type: 'JSONString' },
          { title: 'isActive', value: this.isActive, type: 'Boolean' },

        )
      }
      const variables = {}
      let mutation = 'mutation('
      let query = `{updateUserVerificator(id:"${id}",input:{`
      objectVariables.forEach(element => {
        variables[element.title] = element.value
        mutation += `$${element.title}:${element.type},\n`
        query += `${element.title}:$${element.title},\n`
      })
      mutation += ')'
      query += `}){
        vUserVerificator{
                  id
                }
          }
        }`
      query = mutation + query
      data.append('query', query)
      data.append('variables', JSON.stringify(variables))
      data.append('image', this.image)

      axios
        .post('', data, { headers }, config)
        .then(res => {
          messageError(res, this)

          showToast(this.$t('success'), 1, this)
          this.$emit('refresh')
        })
        .catch(res => {
          // eslint-disable-next-line
          console.log(res)
          this.show = false
          showToast(this.$t('error'), 2, this)
        })
    },
    catMenu() {
      if (this.createBool) { this.addVerificator() } else { this.updateVerificator(this.id) }
    },

    CategorySelection(data) {
      this.categoryName = data.node.name
      this.categoryId = data.node.id
      const state = data.node.state === 'GRE'

      if (this.menuType === 'CAT') this.activeCat = state
      else this.activeCat = true
      this.originalStatus = this.activeCat

      this.messageDeactive = this.$t('deacCateg')
      this.isActive = state ? this.isActive : false
      this.hideModal()
    },

    iconFontAwesome(data) {
      const [prefix, icon] = data
      this.prefix = prefix
      this.icon = icon
      this.hideModal()
    },
    buildImageUrl(folder) {
      return folder == null || folder.length === 0
        ? noCover
        : folder
    },

    getPrefix(data, type = 0) {
      const style = data.split(' ')[0]
      const guion = data.split('-')
      guion.shift()
      const res = guion.join('-')
      let prefijo = null

      switch (type) {
        case 1:
          prefijo = res
          break
        case 2:
          prefijo = style
          break
        default:
          prefijo = [style, res]
          break
      }

      return prefijo
    },
    validationFormInfo() {
      return new Promise(resolve => {
        this.$refs.menuRules.validate().then(success => {
          if (success && isEmpty(this.jsonError)) {
            resolve(true)
            this.catMenu()
          }
        })
      })
    },

    validationMessage(data) {
      const keyContent = Object.keys(data.failedRules)
      if (keyContent.includes('required')) {
        return this.$t('required')
      }
      return ''
    },
  },
}
</script>
<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";

#menu-view .card-body {
  padding: 1rem;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  min-height: 1px;
}

#menu-view .card-body h4 {
  font-size: 1.286rem !important;
}

#menu-view .col-form-label {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

#menu-view .fly-image-input {
  max-width: 100%;
}

#menu-view .base-image-input {
  display: block;

  background-size: cover;
  background-position: center center;
}

#menu-view .base-color-input {
  display: block;
  border-radius: 15px;
  border: solid 1px;
  border-color: white;
  cursor: pointer;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#menu-view .base-card-input {
  display: block;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

#menu-view .base-image-inputIm {
  display: block;

  width: 35vh;
  height: 20vh;
  margin: auto;
  object-fit: contain;
  -webkit-mask-size: contain;
}

#menu-view .div {
  display: inline-block;
}

#menu-view .placeholder {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}

#menu-view .placeholder:hover {
  background: #e0e0e0;
}

#menu-view .file-input {
  display: none;
}

#menu-view .custom-control-label {
  font-size: 15px;
}

#menu-view .scroll {
  overflow: hidden;
  flex-wrap: nowrap !important;

}

#menu-view .list {
  overflow: hidden;
  overflow-y: scroll;
  height: 380px;
}

#menu-view ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  display: none;
}

#menu-view .contain {
  object-fit: contain;
  cursor: pointer;
}

#menu-view .active-info {
  display: flex;
  align-items: center;
  width: 70%;
  font-weight: bold;
  color: aquamarine;
  font-size: smaller;
}

#menu-view .dropContainer {
  border: 3px dashed;
}
</style>
